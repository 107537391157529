@font-face {
  font-family: 'Noto Sans SC';
  font-weight: 700;
  src: local('Awesome Font'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-sc/NotoSansSC-Bold.woff2'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-sc/NotoSansSC-Bold.woff');
  unicode-range: U+4E00-9FFF;
}

@font-face {
  font-family: 'Noto Sans SC';
  font-weight: 600;
  src: local('Awesome Font'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-sc/NotoSansSC-SemiBold.woff2'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-sc/NotoSansSC-SemiBold.woff');
  unicode-range: U+4E00-9FFF;
}

@font-face {
  font-family: 'Noto Sans SC';
  font-weight: 500;
  src: local('Awesome Font'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-sc/NotoSansSC-Medium.woff2'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-sc/NotoSansSC-Medium.woff');
  unicode-range: U+4E00-9FFF;
}

@font-face {
  font-family: 'Noto Sans SC';
  font-weight: 300;
  src: local('Awesome Font'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-sc/NotoSansSC-Light.woff2'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-sc/NotoSansSC-Light.woff');
  unicode-range: U+4E00-9FFF;
}

@font-face {
  font-family: 'Noto Sans SC';
  font-weight: 400;
  src: local('Awesome Font'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-sc/NotoSansSC-Regular.woff2'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-sc/NotoSansSC-Regular.woff');
  unicode-range: U+4E00-9FFF;
}

@font-face {
  font-family: 'Noto Sans TC';
  font-weight: 700;
  src: local('Google Fonts'), url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-tc/NotoSansTC.woff2'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-tc/NotoSansTC.woff');
  unicode-range: U+4E00-9FFF;
}

@font-face {
  font-family: 'Noto Sans TC';
  font-weight: 600;
  src: local('Google Fonts'), url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-tc/NotoSansTC.woff2'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-tc/NotoSansTC.woff');
  unicode-range: U+4E00-9FFF;
}

@font-face {
  font-family: 'Noto Sans TC';
  font-weight: 500;
  src: local('Google Fonts'), url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-tc/NotoSansTC.woff2'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-tc/NotoSansTC.woff');
  unicode-range: U+4E00-9FFF;
}

@font-face {
  font-family: 'Noto Sans TC';
  font-weight: 400;
  src: local('Google Fonts'), url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-tc/NotoSansTC.woff2'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-tc/NotoSansTC.woff');
  unicode-range: U+4E00-9FFF;
}

@font-face {
  font-family: 'Noto Sans TC';
  font-weight: 300;
  src: local('Google Fonts'), url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-tc/NotoSansTC.woff2'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-tc/NotoSansTC.woff');
  unicode-range: U+4E00-9FFF;
}

@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 600;
  src: local('Google Fonts'), url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-jp/NotoSansJP.woff2'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-jp/NotoSansJP.woff2');
  unicode-range: U+3000-30FF, U+4E00-9FFF, U+FF00-FFEF;
}

@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 600;
  src: local('Google Fonts'), url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-jp/NotoSansJP.woff2'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-jp/NotoSansJP.woff2');
  unicode-range: U+3000-30FF, U+4E00-9FFF, U+FF00-FFEF;
}

@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 500;
  src: local('Google Fonts'), url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-jp/NotoSansJP.woff2'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-jp/NotoSansJP.woff2');
  unicode-range: U+3000-30FF, U+4E00-9FFF, U+FF00-FFEF;
}

@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 300;
  src: local('Google Fonts'), url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-jp/NotoSansJP.woff2'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-jp/NotoSansJP.woff2');
  unicode-range: U+3000-30FF, U+4E00-9FFF, U+FF00-FFEF;
}

@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 200;
  src: local('Google Fonts'), url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-jp/NotoSansJP.woff2'),
    url('https://cdn.carro.co/carro-webs/assets/shared/fonts/noto-sans-jp/NotoSansJP.woff2');
  unicode-range: U+3000-30FF, U+4E00-9FFF, U+FF00-FFEF;
}
